<template>
  <div class="roentgenography">
    <div class="title-and-buttons">
      <div class="title">
        <h1>Последно направени рентгенографии</h1>
        <p class="accent">{{ total }} бр.</p>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table" v-if="photos.length">
        <thead>
          <tr>
            <th>ПАЦИЕНТ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="photo"
            v-for="p of photos"
            :key="p._id"
            @click="canPreview(p.photo) ? (selectedPhoto = `${p.examination}/${p.photo}`, selectedPhotoCategory = p.category) : null"
          >
            <td>
              <p>
                {{ p.user ? p.user.name : '-'}}
                <template
                  v-if="p.teeth"
                >- {{ p.teeth.length > 1 ? 'зъби' : 'зъб' }} {{ p.teeth.join(', ') }}</template>
                <span class="date">{{ p.createdAt | formatDateFull }}</span>
              </p>
            </td>
            <td>
              <p class="right controlls">
                <router-link
                  class="download"
                  :to="`/to-jpg/${p.examination}/${p.photo}`"
                  target="_blank"
                  @click.stop.native
                  v-if="p.photo.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/)"
                >
                  <span class="icon">f</span>
                  JPEG
                </router-link>
                <a
                  class="download"
                  :href="`${uploads}/photos/${p.examination}/${p.photo}`"
                  target="_blank"
                  @click.stop
                  title="Сваляне на снимката"
                >
                  <span class="icon">f</span>
                  <template v-if="p.photo.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/)">DICOM</template>
                  <template v-else-if="p.photo.match(/\.(jpg|JPG|jpeg|JPEG)$/)">JPEG</template>
                  <template v-else-if="p.photo.match(/\.(bmp|BMP)$/)">BMP</template>
                  <template v-else-if="p.photo.match(/\.(ilumact|ILUMACT)$/)">ILUMACT</template>
                  <template v-else-if="p.photo.match(/\.(zip|ZIP)$/)">ZIP</template>
                </a>
                <span
                  class="icon edit"
                  @click.stop="editPhoto(p._id)"
                  title="Редактиране типа на изследването"
                >J</span>
                <span class="icon" @click.stop="deletePhoto(p._id)" title="Изтриване на снимката">e</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :total="total" :size="size" />
    </div>
    <transition name="fade">
      <PhotoModal
        v-if="selectedPhoto"
        :path="selectedPhoto"
        :category="selectedPhotoCategory"
        @close="selectedPhoto = null, selectedPhotoCategory = ''"
      />
    </transition>
    <transition name="fade">
      <PhotoUpload v-if="isPhotoUploadOpen" @close="closeEditPhoto" :id="selectedPhotoId" />
    </transition>
  </div>
</template>

<script>
import PhotoModal from '@/components/PhotoModal';
import Pagination from '@/components/Pagination';
import PhotoUpload from './PhotoUpload';
export default {
  components: {
    PhotoModal,
    Pagination,
    PhotoUpload,
  },
  computed: {
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      total: 0,
      size: 16,
      isPhotoUploadOpen: false,
      selectedPhotoId: null,
      selectedPhoto: null,
      selectedPhotoCategory: '',
      photos: [],
      timeout: null,
    };
  },
  watch: {
    $route: {
      handler: 'getPhotos',
      immediate: true,
    },
  },
  methods: {
    getPhotos() {
      this.photos = [];
      this.total = 0;
      const url = `/photos?size=${this.size}&page=${
        this.$route.query.page || 1
      }`;
      this.$apiService.get(url).then((res) => {
        this.photos = res.data.photos;
        this.total = res.data.total;
      });
    },
    canPreview(i) {
      return i.toLowerCase().match(/\.(dcm|dic|dicom|jpg|jpeg|bmp)$/);
    },
    editPhoto(id) {
      this.selectedPhotoId = id;
      this.isPhotoUploadOpen = true;
    },
    closeEditPhoto(update) {
      this.isPhotoUploadOpen = false;
      this.selectedPhotoId = null;

      if (update) {
        this.getPhotos();
      }
    },
    deletePhoto(id) {
      if (confirm('Сигурни ли сте, че искате да изтриете снимката?')) {
        this.$apiService.delete(`/photos/${id}`).then(() => {
          this.getPhotos();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';
.photos-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  width: 100%;
}

.controlls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
td {
  .date {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9rem;
    margin-left: 3px;
  }
  .icon {
    font-size: 1.4rem;
    @include transition(all $transition $cubic);
    &:hover {
      color: #810900;
    }

    &.edit {
      margin-right: 10px;
      &:hover {
        color: #0077c7;
      }
    }
  }
  .download {
    align-items: center;
    background-color: #fff;
    border: 1px solid #b5b5b5;
    border-radius: 2px;
    color: #414141;
    display: inline-flex;
    font-size: 0.8rem;
    height: 24px;
    justify-content: space-between;
    line-height: 0.8rem;
    margin: -2px 12px -2px 0;
    padding: 0 5px;
    width: 70px;
    @include transition(all $transition $cubic);
    .icon {
      color: inherit;
      font-size: 1rem;
      line-height: 1rem;
    }
    &:hover {
      background-color: rgba($color: #000, $alpha: 0.05);
    }
  }
}
</style>